<template>
    <div class="login_bg">
        <div class="login_form ">
            <mdb-row>
                <mdb-col col="12" sm="8" md="6" lg="4" xl="3">
                    <mdb-card>
                        <mdb-card-body>
                            <h5 class="card-title">登入系統</h5>
                            <form @submit.prevent="login()">
                                <mdb-input label="帳號" v-model="acc" />
                                <mdb-input type="password" label="密碼" v-model="pw" />
                                <mdbBtn type="submit" color="primary" block>
                                    登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;入
                                </mdbBtn>
                            </form>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </div>
    </div>
</template>
<style lang="scss">
    .login_bg {
        background-image: url(../assets/login_bg.jpg);
    }
</style>
<script>
    import Vue from 'vue';
    import { VueReCaptcha } from 'vue-recaptcha-v3';
    import { mdbCard, mdbCardBody, mdbBtn, mdbInput, mdbRow, mdbCol } from 'mdbvue';
    Vue.use(VueReCaptcha, {
        siteKey: '6LfnC_YnAAAAAKTHhqkBFquftaSAyJMMrewoRNcv',
    });

    export default {
        props: {
            query: {
                default: {},
            },
        },
        components: {
            mdbCard,
            mdbCardBody,
            mdbBtn,
            mdbInput,
            mdbRow,
            mdbCol,
        },
        data() {
            return {
                acc: '',
                pw: '',
                token: '',
                loading: false,
                storeList: [],
            };
        },
        mounted() {
            this.$cookie.remove('login_token');
            this.recaptcha();
            let vue = this;
            vue.$store.dispatch('get_form', {
                payload: {
                    url: 'auth/login',
                },
            });
        },
        methods: {
            async recaptcha() {
                await this.$recaptchaLoaded();
                // Execute reCAPTCHA with action "login".
                this.token = await this.$recaptcha('login');
            },
            login() {
                let vue = this,
                    msg = '';
                switch (true) {
                    case vue.acc == '':
                        msg = '請輸入帳號！';
                        break;
                    case vue.pw == '':
                        msg = '請輸入密碼';
                        break;
                }
                if (msg != '') {
                    vue.$swal.fire({
                        title: msg,
                        toast: true,
                        timer: 1500,
                        showConfirmButton: false,
                        icon: 'warning',
                    });
                } else {
                    vue.recaptcha();
                    vue.loading = true;
                    vue.$swal.fire({
                        title: '讀取中...',
                        showConfirmButton: false,
                        // toast: true,
                        html: `<i class="fa fa-spinner fa-pulse fa-4x" style="color:#007bff"></i>`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen() {
                            vue.$store.dispatch('login', {
                                account: vue.acc,
                                password: vue.pw,
                                'g-recaptcha-response': vue.token,
                                callback(res) {
                                    if (res.data.status == 'login_success') {
                                        vue.$swal.fire({
                                            title: '登入成功！',
                                            timer: 1500,
                                            icon: 'success',
                                            showConfirmButton: false,
                                            didClose() {
                                                vue.$router.push({
                                                    path: '/',
                                                });
                                            },
                                        });
                                    } else {
                                        vue.$swal.fire({
                                            icon: 'error',
                                            title: res.data.msg,
                                            showConfirmButton: false,
                                            // timer: 1500,
                                        });
                                    }
                                },
                            });
                        },
                    });
                }
            },
        },
    };
</script>
